.table {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    margin: 0;
    width: 50%;
    padding: 15px;
}

.head {
    color: #485aff;
    text-decoration: underline;
    font-weight: 500;
}

.name {
    color: #5b37ff;
    text-decoration: underline;
    font-weight: 500;
}

.name:hover {
    color: #220d77;
    cursor: pointer;
}